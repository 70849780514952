.searchimg {
  width: 100%;
  height: 88vh;
  background: #00af92 0% 0% no-repeat padding-box;
  padding: auto;
  clip-path: polygon(12% 0, 100% 0, 100% 91%, 0% 100%);
  opacity: 0.9;
}

.searchimage {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  padding: 40px;
}
.searchleftside {
  margin-top: 20px;
  margin-bottom: auto;
  margin-right: auto;
}

.input {
  height: 5vh;
  width: 75%;
  padding: auto;
  margin-right: 5px;
}
.searchbutton {
  width: auto;
  height: 5vh;
  padding: auto;
}
@media screen and (max-width: 1200px) {
  .searchimg {
    width: 100%;
    height: 65vh;
    background: #00af92 0% 0% no-repeat padding-box;
    padding: auto;
    clip-path: polygon(12% 0, 100% 0, 100% 91%, 0% 100%);
    opacity: 0.9;
  }
  .searchimage {
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;

    padding: 40px;
  }
  .input {
    height: 5vh;
    width: 75%;
    padding: auto;
    margin-right: 5px;
  }
  .searchbutton {
    width: auto;
    height: 5vh;
    padding: auto;
  }
}
@media screen and (max-width: 992px) {
  .col-md-6 {
    width: 100%;
  }
  .searchimg {
    display: none;
  }

  .searchleftside {
    padding: auto;
    margin: auto;
  }
}
@media screen and (max-width: 500px) {
  .col-md-6 {
    width: 100%;
  }
  .input {
    height: 5vh;
    width: 60%;
    padding: auto;
    margin-right: 5px;
  }
  .searchbutton {
    width: auto;
    height: 5vh;
  }
  .searchimg {
    display: none;
  }

  .searchleftside {
    padding: auto;
    margin: auto;
  }
}
