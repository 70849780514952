.comparison {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "Poppins", sans-serif;
  background-image: url("../../../../public/bgIg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.boxClgOne {
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  background: linear-gradient(
    -45deg,
    rgb(226, 81, 81),
    rgba(210, 251, 77, 0.711),
    rgb(255, 73, 73),
    rgba(255, 0, 140, 0.545)
  );
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.boxClgTwo {
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  background: linear-gradient(
    -45deg,
    #46504e,
    #ffffffb7,
    hsl(180, 7%, 32%),
    #bababa
  );
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.boxClgThree {
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #516fe7, #73dbc5f0, #a0dda3e4, #4acddc);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

.btnClg {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  margin-top: 30px;
  margin-bottom: 50px;
  cursor: pointer;
}

/* 
========================
      BUTTON ONE
========================
*/
.btnClgOneDesign {
  color: #fff;
  transition: all 0.3s;
  position: relative;
}
.btnClgOneDesign span {
  transition: all 0.3s;
  color: white;
}
.btnClgOneDesign::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}
.btnClgOneDesign:hover span {
  letter-spacing: 2px;
}
.btnClgOneDesign:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.btnClgOneDesign::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.btnClgOneDesign:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

/* 
========================
      BUTTON TWO
========================
*/
.btnClgTwoDesign {
  color: #fff;
  transition: all 0.5s;
  position: relative;
}
.btnClgTwoDesign span {
  z-index: 2;
  color: white;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
.btnClgTwoDesign::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
}
.btnClgTwoDesign::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
}
.btnClgTwoDesign:hover::before {
  transform: rotate(-45deg);
  background-color: rgba(255, 255, 255, 0);
}
.btnClgTwoDesign:hover::after {
  transform: rotate(45deg);
  background-color: rgba(255, 255, 255, 0);
}

/* 
========================
      BUTTON THREE
========================
*/
.btnClgThreeDesign {
  color: #fff;
  transition: all 0.5s;
  position: relative;
}

.btnClgThreeDesign span {
  z-index: 2;
  color: white;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

.btnClgThreeDesign::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}
.btnClgThreeDesign:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.btnClgThreeDesign::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: scale(1.2, 1.2);
}
.btnClgThreeDesign:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.vsImg img {
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  position: relative;
  margin-top: 50vh;
}

.ClgCompareData {
  background-color: aquamarine;
  padding: 0px;
  height: auto;
  width: fit-content;
  padding: 20px;
  justify-content: center;
  align-items: center;
  left: 10px;
}

.compareDataDiv {
  margin: 5px;
  width: 200px;
  border: 1px solid black;
  border-radius: 5px;
}

.compareDataTitle {
  width: 199px;
  border-bottom: 2px solid black;
  height: fit-content;
  background-color: hsl(0, 0%, 43%);
  color: white;
  text-align: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
}

.compareData {
  background-color: rgb(236, 255, 252);
  width: 198px;
  height: auto;
  padding: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
}
.nameOfRec {
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

.dataCols {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.modalCollegeList {
  display: none;
  position: fixed;
  z-index: 9;
  padding: 100px;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.comparisonCollegeList {
  background-color: #eee;
  padding: 50px;
  margin: auto;
  border-radius: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modalCollegeListBtns {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.collegeListBtn {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: #c4ffb3;
  box-shadow: 1px 1px 1px 1px #dcdddd;
  border: none;
  padding: 10px;
  margin: 3px;
  color: rgb(23, 51, 5);
  text-align: left;
}

.visitNowBtn {
  background-color: #516fe7;
  padding: 10px;
  color: white;
  border-radius: 10px;
}
