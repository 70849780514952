.videoCourse {
  background-color: rgba(237, 242, 246, 1);
  display: flex;
  flex-direction: column;
}

.courseVideos {
  display: flex;
  flex-direction: row;
}

.videoCourseHeading {
  color: tomato;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  text-align: left;
  padding: 10px;
  font-weight: 500;
  border-radius: 10px;
  background-color: white;
}

.videoMainPanel {
  flex: 8;
  display: block;
  width: 100%;
  height: 100%;
  margin: 10px;
  margin-left: 30px;
  margin-bottom: 50px;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.videoMainPanelItem {
  width: 100%;
  height: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px;
  background-color: white;
}
.videoMainPanel .videoMainPanelItem .video {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 10px;
}

.videoMainPanel .videoMainPanelItem .videoMainPanelTitle {
  color: #333;
  display: flex;
  flex-direction: row;
  font-size: 24px;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgb(240, 240, 240);
  width: 100%;
  border-left: 10px solid tomato;
  border-right: 10px solid tomato;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: 550;
}

.player .ctrls {
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  background: rgba(62, 62, 62, 0.54);
  z-index: 9;
  padding: 0px 15px;
  margin-bottom: 5px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.player .ctrls .pp {
  border: 0px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: tomato;
  color: #fff;
}

.player .ctrls .timer {
  flex: 1;
  width: fit-content;
  margin: 0px;
  margin-left: 0px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.player .ctrls .slider {
  flex: 3;
  width: 100%;
}

.player .ctrls .slider .prog {
  width: 100%;
  height: 8px;
  background-color: #ffffff;
  border-radius: 8px;
}

.player .ctrls .slider .bar {
  height: 8px;
  background: tomato;
  border-radius: 8px;
}

.player .ctrls .slider .bar .knob {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  box-shadow: 0px 0px 0px #000;
}

.player .ctrls .vol {
  border: 0px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  transform: translateY(-5%);
  background: rgba(234, 82, 82, 0);
  color: #fff;
  margin: 0px 5px;
}

.player .ctrls .fs {
  border: 0px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background: rgba(234, 82, 82, 0);
  color: #fff;
  margin: 0px 5px;
}

.player .ctrls .fastfor {
  border: 0px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background: rgba(234, 82, 82, 0);
  color: #fff;
  margin: 0px 5px;
}

.player .ctrls .revert {
  border: 0px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background: rgba(234, 82, 82, 0);
  color: #fff;
  margin: 0px 5px;
}

.course-preview {
  flex: 2;
  margin-left: 20px;
}

.videoListPanel ::-webkit-scrollbar {
  width: 2px;
}

.videoListPanel ::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 50px;
}

.videoListPanel ::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 50px;
}

.videoListPanel {
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  margin-right: 30px;
  margin-top: 30px;
  padding: 15px;
  cursor: pointer;
}

.videoListPanelItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: rgb(235, 235, 235);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.videoListPanel .videoListPanelItem:hover {
  border-left: 10px solid tomato;
}

.videoListPanel .videoListPanelItem.active {
  background: #2980b9;
}

.videoListPanel .videoListPanelItem.active .videoSectionTitle {
  color: #fff;
}

.videoListPanel .videoListPanelItem .videoSectionTitle {
  color: rgb(0, 0, 0);
  font-size: 17px;
}

.videoSectionTitle {
  width: 100%;
  background-color: rgb(235, 235, 235);
  display: flex;
  flex-direction: row;
  font-size: 20px;
  width: 100%;
  align-items: left;
  justify-content: left;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  border: none;
}

.sidebarTitle {
  width: 100%;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 450;
  color: #444;
}

.videoLectureTitle {
  background-color: rgb(248, 248, 248);
  display: flex;
  box-shadow: 0.4px 0.4px 0.8px 0.8px rgb(220, 220, 220);
  width: 95%;
  flex-direction: row;
  font-size: 17px;
  margin-top: 10px;
  align-items: left;
  justify-content: left;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.title {
  width: 100%;
}

.dropIcon {
  height: 100%;
  width: fit-content;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.videoSectionSidebarIcon {
  color: black;
  font-size: 20px;
  padding-right: 30px;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateX(50%);
}

.videoSectionIcon {
  color: black;
  font-size: 25px;
  padding-right: 30px;
  margin-bottom: 30px;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateY(50%);
  transform: translateX(30%);
}

@media (max-width: 991px) {
  .videoMainPanel {
    flex: 7;
  }
  .videoListPanel {
    flex: 3;
  }
}

@media (max-width: 768px) {
  .courseVideos {
    flex-direction: column;
  }
  .videoMainPanel {
    flex: 10;
    margin: 10px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .videoListPanel {
    flex: 10;
    margin: 10px;
    margin-left: 30px;
    margin-bottom: 50px;
  }
}

.video-js .vjs-big-play-button {
  background-color: tomato !important;
  height: 50px;
  width: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-color: tomato;
  border-radius: 50%;
}

.subVideo {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.subVideo :hover {
  border-left: 5px solid tomato;
}

.staticTitleSC {
  color: black;
  font-size: 23px;
  font-family: "Poppins", sans-serif;
  margin-top: 25px;
  line-height: 30px;
}
.videoMainPanelDesc {
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
}

.descContent {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-top: 15px;
  line-height: 30px;
  text-align: justify;
  font-weight: 350;
  width: 95%;
  margin: 12px;
}

.flex {
  display: flex;
}
.aic {
  align-items: center;
}
.jic {
  justify-content: center;
}
.anim {
  transition: all 0.2s linear 0s;
}
.fixed {
  position: fixed;
}
.abs {
  position: absolute;
}

.abc {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rel {
  position: relative;
}

.noul {
  text-decoration: none;
}

.ibl {
  display: inline-block;
}
.bl {
  display: block;
}

.cfff {
  color: #fff;
}
.c333 {
  color: #333;
}
.c777 {
  color: #777;
}

.s13 {
  font-size: 13px;
}
.s14 {
  font-size: 14px;
}
.s15 {
  font-size: 15px;
}
.s18 {
  font-size: 18px;
}
.s20 {
  font-size: 20px;
}
.s24 {
  font-size: 24px;
}
.s40 {
  font-size: 40px;
}
.s50 {
  font-size: 50px;
}

.fontn {
  font-weight: normal;
}
.fontb {
  font-weight: bold;
}

.slider-cont {
  margin: 10px;
  display: flex;
  align-items: center;
}

.sliderVol {
  position: relative;
  width: 100px;
  height: 10px;
}

.sliderVol input {
  position: absolute;
  top: 0;
  width: 100px;
  height: 10px;
  -webkit-appearance: none;
  background-color: transparent;
  z-index: 99;
}

.sliderVol input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid tomato;
  border-radius: 50%;
  cursor: pointer;
}

.sliderVol progress {
  width: 100px;
  height: 10px;
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
}

.sliderVol progress::-webkit-progress-bar {
  background-color: #f0f0f0;
}

.sliderVol progress::-webkit-progress-value {
  background-color: tomato;
}

.sliderValue {
  text-align: center;
  justify-content: center;
  align-items: center;
}
