.bordericon {
  background-color: #1ab99d38;
  padding: 5px;
  margin-right: 5px;
  font-size: small;
  cursor: pointer;
}
.cardimgs {
  height: 20vh !important;
  width: auto;
}
.cardname {
  font-size: 18px;
  cursor: pointer;
}
