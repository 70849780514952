.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.courseOverview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 3px rgb(210, 210, 210);
}

.panelHeader {
  text-align: center;
  font-weight: 900;
  font-size: 26px;

  font-family: "Poppins", sans-serif;
}

.courseTitlePanel,
.courseCategory,
.coursePrerequisite,
.targetAudience,
.courseCaption {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  width: 100%;
  margin: 10px;
  font-family: "Poppins", sans-serif;
}

.questionLabel {
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.answerField {
  font-family: "Poppins", sans-serif;
  border-width: 2px;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.courseSection {
  display: flex;
  flex-direction: column;
  margin: 30px;
  padding: 30px;
  height: 100%;
  border-radius: 12px;
  box-shadow: 1px 1px 3px 3px rgb(210, 210, 210);
}

.panelHeader {
  text-align: center;
  font-weight: 900;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}

.Section {
  width: 95%;
  box-shadow: 2px 2px 5px 5px rgb(187, 187, 187);
  padding: 10px;
  padding-right: 30px;
  margin: 10px;
  border-radius: 10px;
}

.Lecture {
  width: 95%;
  box-shadow: 1px 1px 3px 3px rgb(216, 216, 216);
  padding: 10px;
  padding-right: 50px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.courseSectionName,
.courseVideoUpload,
.courseLectureName,
.courseLectureDesc,
.courseResourceUpload {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  width: 100%;
  margin: 10px;
  font-family: "Poppins", sans-serif;
}

.courseSectionNameLabel,
.courseLectureNameLabel,
.courseResourceLabel,
.courseLectureDescLabel {
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.courseLectureNameInput,
.courseSectionNameInput {
  font-family: "Poppins", sans-serif;
  border-width: 2px;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.courseLectureDescInput {
  font-family: "Poppins", sans-serif;
  height: 30px;
  resize: none;
  border-radius: 0%;
  font-size: 16px;
}
.sectionButton,
.lectureButton {
  width: 120px;
  height: 40px;
  text-align: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  padding: auto;
  color: white;
  background-color: black;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
}

.courseVideoUpload {
  font-size: 50px;
  box-shadow: 0.4px 0.4px 1px 1px rgb(0, 0, 0);
  color: rgb(30, 110, 180);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.landingPageForm {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
  padding: 30px;
  height: 100%;
  box-shadow: 2px 2px 5px 5px rgb(210, 210, 210);
}

.landingPageTitle,
.landingPageSubTitle,
.courseLanguage,
.courseImageUpload,
.coursePromoUpload,
.courseDifficulty,
.landingPageCategory,
.landingPageLearning,
.coursePrice,
.courseCoupon,
.landingPageDesc {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  width: 100%;
  margin: 10px;
  font-family: "Poppins", sans-serif;
}

.landingPageTitleLabel,
.landingPageSubTitleLabel,
.courseLanguageLabel,
.courseDifficultyLabel,
.landingPageCategoryLabel,
.courseImageLabel,
.coursePriceLabel,
.landingPageLearningLabel,
.coursePromoLabel,
.courseCouponLabel,
.landingPageDescLabel {
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-align: left;
}

.landingPageTitleInput,
.landingPageSubTitleInput,
.landingPageDescInput,
.courseLanguageInput,
.courseDifficultyInput,
.landingPageCategoryInput,
.landingPageLearningInput,
.coursePriceInput,
.courseCouponInput {
  font-family: "Poppins", sans-serif;
  border-width: 2px;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.landingPageDescInput {
  font-family: "Poppins", sans-serif;
  height: 30px;
  resize: none;
  border-radius: 0%;
  font-size: 16px;
}

.courseImageUpload,
.coursePromoUpload {
  font-size: 50px;
  box-shadow: 0.4px 0.4px 1px 1px black;
  border-radius: 5px;
  color: rgb(81, 126, 171);
  text-align: center;
}

.courseVideoUpload :hover {
  cursor: pointer;
}

.coursePromoUpload :hover {
  cursor: pointer;
}

.courseImageUpload :hover {
  cursor: pointer;
}

.courseResourceUpload :hover {
  cursor: pointer;
}

.messagePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 2px 2px 5px 5px rgb(210, 210, 210);
}

.courseMode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
  border-radius: 10px;
  padding: 30px;
  height: 420px;
  box-shadow: 2px 2px 5px 5px rgb(210, 210, 210);
}

.messagePanelWelcome,
.messagePanelCongo,
.courseModePanel {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  width: 100%;
  margin: 10px;
  font-family: "Poppins", sans-serif;
}

.messagePanelWelomeLabel,
.messagePanelCongoLabel,
.courseModeLabel {
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-align: left;
}

.courseModeInput {
  font-family: "Poppins", sans-serif;
  border-width: 2px;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  width: 100%;
  height: 35px;
}

.messagePanelCongoText,
.messagePanelWelcomeText {
  font-family: "Poppins", sans-serif;
  height: 120px;
  resize: none;
  border-radius: 0%;
  font-size: 16px;
}

.formButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formButtonbBack,
.formButtonNext,
.formButtonSubmit {
  width: 70px;
  height: 40px;
  text-align: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  padding: auto;
  color: white;
  background-color: black;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
}

.deleteSection,
.deleteLecture {
  background-color: none;
  color: tomato;
  text-align: right;
  font-size: 20px;
  cursor: pointer;
}

.requiredIcon {
  color: tomato;
  margin-left: 2px;
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(255, 79, 79);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px 2px;
  position: absolute;
  z-index: 1;
}

.requiredIcon:hover .tooltiptext {
  visibility: visible;
}

.resourceLabel {
  color: rgb(73, 99, 177);
  font-size: 30px;
  margin: 10px;
}

.containerUpload {
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fcfcfc;
}

.cardUpload {
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  width: 600px;
  height: 260px;
  background-color: #ffffff;
  padding: 10px 30px 40px;
}

.cardUpload h3 {
  font-size: 22px;
  font-weight: 600;
}

.drop_boxUpload {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}

.drop_boxUpload h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_boxUpload p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}

.btnUpload {
  text-decoration: none;
  background-color: #005af0;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
}

.btnUpload:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #005af0;
  padding: 10px 20px;
  border: none;
  outline: 1px solid #010101;
}
.formUpload input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}
