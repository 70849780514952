* {
  box-sizing: border-box;
}
.ArticleContainer {
  width: 500px;
  margin: 0 auto;
  margin-top: 20px;
}
.AddArticle {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.AddArticle input,
.AddArticle textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 5px 0;
  padding: 5px;
}
.AddArticle input[type="submit"] {
  padding: 5px;
  background-color: greenyellow;
  border: none;
  cursor: pointer;
}
h1 {
  text-align: center;
}
article {
  padding: 10px;
  border: 1px solid #ddd;
  margin: 10px auto 20px;
  width: 100%;
}
article h2 {
  color: orange;
  font-size: 30px;
  margin: 5px 0;
}
article button {
  width: 50px;
  margin: 0 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
}
article .delete {
  color: red;
}
article .edit {
  color: green;
}
article .cancel {
  color: orange;
}
