/* .footerFluidContainer{
    background: #20202A;
    height: 30.875rem;
    margin-top: 0%;
    color: #FFFFFF;
    
}
.footerLogo{
    height: 96px;
    width: 481px;
    margin-top: 82px;
    margin-left: 409px;
}
.footerCompanyText{
    text-align: left;
    font: normal normal medium 26px/39px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 96px;
    font-size: 1.625rem;
}
.footerAboutusText{
    text-align: left;
    font: normal normal normal 22px/33px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.54;
    margin-top: 6px;
}
.footerSupportText{
    text-align: left;
    font: normal normal medium 26px/39px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 96px;
    font-size: 1.625rem;
}
.footerHelpText{
    text-align: left;
    font: normal normal normal 22px/33px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.54;
    margin-top: 6px;
}
.footerContactusText{
    text-align: left;
    font: normal normal normal 22px/33px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.54;
}
.footerLine{
    border-top: 1px solid #707070;
    margin-left: 400px; 
    margin-right: 130px; 
}
.footerCopyrightText{
    margin-left: 420px;
    margin-top: 45px;
    font: normal normal normal 22px/33px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.36;
}





@media only screen and (max-width: 950px){
    .footerLogo{
        
        
        margin:auto;
        margin-top: 0px;
        width: 250px;

    }
    .footerCompanyText{
       margin-top: 0px;
       margin: auto;
       width: 80px;
       font-size: 22px;
    }
    .footerAboutusText{
        margin-top: 5px;
       margin: auto;
       width: 80px;
       font-size: 18px;
    }
    .footerSupportText{
        margin: auto;
        width: 80px;
        padding-top: 20px;
        font-size: 22px;
    }
    .footerHelpText{
        
       margin: auto;
       width: 80px;
       font-size: 18px;
    }
    .footerContactusText{
        
        margin: auto;
        width: 80px;
        font-size: 18px;
     }
     .footerSocialLogo{
         padding-top: 10px;
         text-align: center;
     }
     .footerLogoCol{
         text-align: center;
     }
     .footerLine{
        border-top: 1px solid #707070;
        margin-left: 10px; 
        margin-right: 10px; 
        margin-top: 25px;

    }
    .footerCopyrightText{
        margin: auto;
        margin-top: 5px;
        font: normal normal normal 18px/33px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.36;
        text-align: center;
    }
    
}
 */
 .fontawesome-style {
    font-size: 1.8rem!important;
    color:#fff;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color:#fff;
    display: grid;
    place-items: center;
    text-align: center;
    margin: 2rem auto;
  }

 
footer {
  position:relative;
   bottom:0;
   width:100%;
    padding: 2rem 0;
    background-color: #333;
    color: #fff;
    margin-bottom: 0;
   
  
  }
  
  footer h2 {
    color: #fff;
    font-size: 1.5rem;
  }
  
  footer li {
    list-style: none;
    font-size: 1.1rem;
    font-weight: lighter;
    color: #fff;
    cursor: pointer;
    margin: 1rem 0;
  }
  
  footer li:hover {
    color: #fff;
  }
  
  footer .fontawesome-style {
    font-size: 2.3rem;
    color: #fff;
    background-color: transparent;
    width: 0rem;
    height: 0rem;
    margin: 2rem 0;
    cursor: pointer;
  }
  
  footer .fontawesome-style:hover {
    transition: all 1s;
    animation: rotatefont 1s linear infinite alternate-reverse;
    color: #fff;
  }
  
  @keyframes rotatefont {
    0% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1.2);
    }
  }
  
  footer .main-hero-para {
    margin: 0;
    
  }
  .footerLogo{
      margin-top:40px;
      margin-bottom: auto;
      height: 8vh;
  }