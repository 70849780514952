:root {
  --bgcolor: #f1f5fd;
  --primary-color: #6f5de7;
  --text-color: #828286;
  --main-text-color: #333333;
}

.contactus-section {
  margin: 5rem 0;
}

.contact-leftside img {
  min-width: 80%;
  max-height: 20rem;
  height: auto;
}

.contact-input-feild {
  margin-bottom: 4rem;
}

.contact-rightside form .form-control {
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  height: 4rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  padding-left: 1rem;
  font-weight: lighter;
}

.contact-rightside form .form-check-input {
}

.form-checkbox-style {
  margin: 2rem 0;
}

.contact-rightside form .main-hero-para {
  font-size: 1.2rem;
  color: #78bddd;
  margin: 0;
  font-weight: lighter;
  width: 75%;
  padding: 0 2rem;
}
.btn-style {
  width: 12rem;

  border-radius: 2rem;
  color: #fff;
  background-color: #78bddd;
  text-transform: capitalize;
  font-size: 1.2rem;
  margin-bottom: 20px;
}
