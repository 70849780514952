/*Webflow standard classes */
html.w-mod-touch * {
  background-attachment: scroll !important;
}
.w-block {
  display: block;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.w-webflow-badge,
.w-webflow-badge * {
  position: static;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: auto;
  display: block;
  visibility: visible;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  box-sizing: border-box;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
  float: none;
  clear: none;
  border: 0 none transparent;
  border-radius: 0;
  background: none;
  background-image: none;
  background-position: 0% 0%;
  background-size: auto auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
  transform: none;
  transition: none;
  direction: ltr;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-style: inherit;
  font-variant: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: 0;
  text-transform: inherit;
  list-style-type: disc;
  text-shadow: none;
  font-smoothing: auto;
  vertical-align: baseline;
  cursor: inherit;
  white-space: inherit;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}
.w-webflow-badge {
  position: fixed !important;
  display: inline-block !important;
  visibility: visible !important;
  opacity: 1 !important;
  z-index: 2147483647 !important;
  top: auto !important;
  right: 12px !important;
  bottom: 12px !important;
  left: auto !important;
  color: #aaadb0 !important;
  background-color: #fff !important;
  border-radius: 3px !important;
  padding: 6px 8px 6px 6px !important;
  font-size: 12px !important;
  opacity: 1 !important;
  line-height: 14px !important;
  text-decoration: none !important;
  transform: none !important;
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  white-space: nowrap;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.w-webflow-badge > img {
  display: inline-block !important;
  visibility: visible !important;
  opacity: 1 !important;
  vertical-align: middle !important;
}

.w-richtext:before,
.w-richtext:after {
  content: " ";
  display: table;
}
.w-richtext:after {
  clear: both;
}
.w-richtext[contenteditable="true"]:before,
.w-richtext[contenteditable="true"]:after {
  white-space: initial;
}

/*-----------------------*/

h3 {
  margin-top: auto;
  margin-bottom: auto;
  font-size: auto;
  line-height: auto;
  font-weight: bold;
}

h6 {
  margin-top: auto;
  margin-bottom: auto;
  font-size: auto;
  line-height: auto;
  font-weight: bold;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

a {
  text-decoration: underline;
}

.scene {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
  -webkit-perspective: 4000px;
  perspective: 4000px;
  -webkit-perspective-origin: 50% 0%;
  perspective-origin: 50% 0%;
}

.book-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 57vw;
  margin-top: 8vw;
  margin-bottom: 8vw;
  padding-right: 1%;
  padding-left: 1%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-perspective: 4000px;
  perspective: 4000px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform: translate3d(0px, 5%, -264px) rotateX(27deg) rotateY(0deg)
    rotateZ(-10deg);
  transform: translate3d(0px, 5%, -264px) rotateX(27deg) rotateY(0deg)
    rotateZ(-10deg);
  -webkit-transition: -webkit-transform 2000ms
    cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform 2000ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 2000ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 2000ms cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 2000ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.book-wrap:hover {
  -webkit-transform: translate3d(0px, 5%, -264px) rotateX(13deg) rotateY(0deg)
    rotateZ(-3deg);
  transform: translate3d(0px, 5%, -264px) rotateX(13deg) rotateY(0deg)
    rotateZ(-3deg);
}

.book-cover-left {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-left-radius: 4%;
  border-bottom-left-radius: 4%;
  background-color: #2e1800;
  box-shadow: inset 4px -4px 4px 1px #635648, inset 7px -7px 4px 0 #221b14;
  -webkit-perspective: 4000px;
  perspective: 4000px;
  -webkit-transform: translate3d(0px, 0px, -1px);
  transform: translate3d(0px, 0px, -1px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.book-cover-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 4%;
  border-bottom-right-radius: 4%;
  background-color: #2e1800;
  box-shadow: inset -4px -4px 4px 1px #635648, inset -7px -7px 4px 0 #221b14;
}

.layer1 {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 10px 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transform: translate3d(0px, 0px, 5px);
  transform: translate3d(0px, 0px, 5px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.page-left {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-left-radius: 1%;
  border-bottom-left-radius: 1%;
  background-color: #fff;
  box-shadow: inset 0 0 26px 2px #d8cccc, -1px 1px 13px 0 rgba(34, 27, 20, 0.81);
}

.layer-text {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 97%;
  margin: 20px 10px 18px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 4000px;
  perspective: 4000px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform: translate3d(0px, 0px, 32px);
  transform: translate3d(0px, 0px, 32px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.layer-text.right {
  -webkit-transform: translate3d(-37px, 0px, 32px);
  transform: translate3d(-37px, 0px, 32px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.page-left-2 {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-left-radius: 18%;
  border-bottom-left-radius: 1%;
  background-color: #fff;
  box-shadow: inset 0 0 7px 4px hsla(0, 13%, 82%, 0.43),
    -1px 1px 13px 0 rgba(34, 27, 20, 0.49);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateX(0deg) rotateY(17deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(17deg) rotateZ(0deg);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition: -webkit-transform 650ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform 650ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 650ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 650ms cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 650ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.page-left-2:hover {
  -webkit-transform: rotateX(0deg) rotateY(7deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(7deg) rotateZ(0deg);
}

.left-side {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 4000px;
  perspective: 4000px;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%;
  -webkit-transform: rotateX(0deg) rotateY(20deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(20deg) rotateZ(0deg);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.right-side {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  -webkit-perspective: 4000px;
  perspective: 4000px;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%;
  -webkit-transform: rotateX(0deg) rotateY(-1deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(-1deg) rotateZ(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.center {
  width: 3%;
  background-image: -webkit-radial-gradient(
      circle farthest-corner at 56% -8%,
      #fff 8%,
      transparent 0
    ),
    -webkit-radial-gradient(circle farthest-corner at 50% 108%, #fff 8%, transparent
          0),
    -webkit-linear-gradient(0deg, #635648, #2e1800 21%, #635648 30%, #2e1800 48%, #635648
          68%, #2e1800 79%, #635648);
  background-image: radial-gradient(
      circle farthest-corner at 56% -8%,
      #fff 8%,
      transparent 0
    ),
    radial-gradient(circle farthest-corner at 50% 108%, #fff 8%, transparent 0),
    linear-gradient(
      90deg,
      #635648,
      #2e1800 21%,
      #635648 30%,
      #2e1800 48%,
      #635648 68%,
      #2e1800 79%,
      #635648
    );
}

.corner-fold {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  border-right: 1px solid hsla(0, 13%, 82%, 0.55);
  border-bottom: 1px solid hsla(0, 13%, 82%, 0.55);
  background-image: -webkit-linear-gradient(
    315deg,
    transparent 47%,
    #f0f0f0 48%,
    #fff 55%,
    #f6f6f6
  );
  background-image: linear-gradient(
    135deg,
    transparent 47%,
    #f0f0f0 48%,
    #fff 55%,
    #f6f6f6
  );
  box-shadow: 6px 6px 9px -4px hsla(0, 13%, 82%, 0.53);
}

.corner {
  position: absolute;
  left: 0px;
  top: 27px;
  width: 5vw;
  height: 5vw;
  background-image: -webkit-linear-gradient(315deg, #fff 30%, transparent);
  background-image: linear-gradient(135deg, #fff 30%, transparent);
  box-shadow: inset 13px 0 17px -12px hsla(0, 13%, 82%, 0.43);
}

.corner2 {
  position: absolute;
  left: 28px;
  top: 0px;
  width: 5vw;
  height: 5vw;
  background-image: -webkit-linear-gradient(315deg, #fff 31%, transparent);
  background-image: linear-gradient(135deg, #fff 31%, transparent);
  box-shadow: inset 0 13px 17px -12px hsla(0, 13%, 82%, 0.43);
}

.page-text {
  position: relative;
  display: block;
  width: 80%;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  font-family: Georgia, Times, "Times New Roman", serif;
}

.page-text p {
  margin-bottom: 0px;
  font-size: 0.85vw;
  line-height: 1.5;
}

.page-text h3 {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-style: italic;
}

.page-text h6 {
  font-family: "PT Sans", sans-serif;
  font-size: 9px;
  text-decoration: none;
}

.page-text a {
  color: #da5a13;
}

.layer2 {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 10px 13px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transform: translate3d(2px, 0px, 10px);
  transform: translate3d(2px, 0px, 10px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.layer2.right {
  -webkit-transform: translate3d(-5px, 0px, 10px);
  transform: translate3d(-5px, 0px, 10px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.layer3 {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 10px 13px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transform: translate3d(4px, 0px, 20px);
  transform: translate3d(4px, 0px, 20px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.layer3.right {
  -webkit-transform: translate3d(-10px, 0px, 20px);
  transform: translate3d(-10px, 0px, 20px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.layer4 {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 10px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transform: translate3d(6px, 0px, 30px);
  transform: translate3d(6px, 0px, 30px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.layer4.right {
  -webkit-transform: translate3d(-15px, 0px, 30px);
  transform: translate3d(-15px, 0px, 30px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.page-right-2 {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 1%;
  border-bottom-right-radius: 1%;
  background-color: #fff;
  box-shadow: inset 0 0 7px 4px hsla(0, 13%, 82%, 0.43),
    1px 1px 13px 0 rgba(34, 27, 20, 0.49);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateX(0deg) rotateY(-3deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(-3deg) rotateZ(0deg);
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform 850ms ease;
  transition: -webkit-transform 850ms ease;
  transition: transform 850ms ease;
  transition: transform 850ms ease, -webkit-transform 850ms ease;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.page-right-2:hover {
  -webkit-transform: rotateX(0deg) rotateY(-17deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(-17deg) rotateZ(0deg);
}

.page-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 1%;
  border-bottom-right-radius: 1%;
  background-color: #fff;
  box-shadow: inset 0 0 26px 2px #d8cccc, 1px 1px 13px 0 rgba(34, 27, 20, 0.81);
}

@media (max-width: 991px) {
  .scene {
    height: 56vw;
  }
  .book-wrap {
    width: 62vw;
  }
  .page-left-2 {
    border-top-left-radius: 20%;
  }
  .corner-fold {
    left: 0%;
    top: -1%;
    width: 3.5vw;
    height: 3.5vw;
    max-height: 35px;
    background-image: -webkit-linear-gradient(
      315deg,
      transparent 50%,
      #f0f0f0 51%,
      #fff 52%,
      #f6f6f6
    );
    background-image: linear-gradient(
      135deg,
      transparent 50%,
      #f0f0f0 51%,
      #fff 52%,
      #f6f6f6
    );
  }
  .corner {
    top: 8%;
  }
  .page-text h3 {
    margin-top: 2vw;
    margin-bottom: 1vw;
    font-size: 3vw;
  }
  .page-text h6 {
    margin-top: 1vw;
    margin-bottom: 1vw;
    font-size: 1vw;
  }
  .page-right-2 {
    padding-left: 9%;
  }
}

@media (max-width: 767px) {
  .book-wrap {
    width: 80vw;
    margin-bottom: 2vw;
  }
  .layer-text {
    width: 94%;
    -webkit-transform: translate3d(0px, 0px, 20px);
    transform: translate3d(0px, 0px, 20px);
  }
  .layer-text.right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-transform: translate3d(-34px, 0px, 24px);
    transform: translate3d(-34px, 0px, 24px);
  }
  .page-left-2 {
    border-top-left-radius: 23%;
    -webkit-transform: rotateX(0deg) rotateY(17deg) rotateZ(0deg)
      translate(9px, 0px);
    transform: rotateX(0deg) rotateY(17deg) rotateZ(0deg) translate(9px, 0px);
  }
  .corner-fold {
    width: 4.5vw;
    height: 4.5vw;
    max-height: 29px;
    min-width: 29px;
    background-image: -webkit-linear-gradient(
      310deg,
      transparent 48%,
      #f0f0f0 49%,
      #fff 50%,
      #f6f6f6
    );
    background-image: linear-gradient(
      140deg,
      transparent 48%,
      #f0f0f0 49%,
      #fff 50%,
      #f6f6f6
    );
  }
  .page-text {
    margin-top: 2vw;
  }
  .page-text h3 {
    margin-top: 0vw;
    font-size: 3vw;
  }
  .layer2 {
    -webkit-transform: translate3d(2px, 0px, 6px);
    transform: translate3d(2px, 0px, 6px);
  }
  .layer2.right {
    -webkit-transform: translate3d(-5px, 0px, 6px);
    transform: translate3d(-5px, 0px, 6px);
  }
  .layer3 {
    -webkit-transform: translate3d(4px, 0px, 12px);
    transform: translate3d(4px, 0px, 12px);
  }
  .layer3.right {
    -webkit-transform: translate3d(-10px, 0px, 12px);
    transform: translate3d(-10px, 0px, 12px);
  }
  .layer4 {
    -webkit-transform: translate3d(6px, 0px, 18px);
    transform: translate3d(6px, 0px, 18px);
  }
  .layer4.right {
    -webkit-transform: translate3d(-15px, 0px, 18px);
    transform: translate3d(-15px, 0px, 18px);
  }
  .page-right-2 {
    width: 92%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
}

@media (max-width: 479px) {
  .scene {
    height: 69vw;
  }
  .corner-fold {
    width: 5.5vw;
    height: 5.5vw;
    min-width: 21px;
  }
}
